import axios, { type AxiosInstance } from 'axios';
import config from '../utils/config';
import Cookies from 'cookies-js';
import { Token } from '../utils/interfaces';
import type ModelAbstract from '../models/modelAbstract/modelAbstract';
import { UrlConstant } from '../utils/interfaces';

export enum ApiType {
	QaApi = 'boosteventsCore',
	BonfireCore = 'bonfireCore',
}
export class BonfireAPI {
	private client: AxiosInstance;
	static instance: BonfireAPI;

	createAxiosClient(baseURL: string, contentTypes?: object) {
		const COOKIE = Cookies.get(config.cookie.name);
		let token: Token | null = null;

		try {
			token = JSON.parse(COOKIE);
		} catch (error) {
			console.log('error', error);
		}

		const defaultHeaders: any = {
			Accept: 'application/json',
		};

		if (token) {
			defaultHeaders.Authorization = `Bearer ${token.access_token}`;
		}

		return axios.create({
			baseURL,
			headers: contentTypes ? { ...defaultHeaders, ...contentTypes } : defaultHeaders,
		});
	}

	constructor(type: ApiType | undefined = ApiType.BonfireCore) {
		this.client = this.createAxiosClient(
			type === ApiType.BonfireCore ? config.bonfire_api.url : config.qa_api.url,
		);
		return;
	}

	async init() {
		this.client = await this.createAxiosClient(config.api.url);
	}
	checkAndRenewToken() {
		return false;
	}

	requestMethod(urlConstant: UrlConstant, payload?: any) {
		const { path, method } = urlConstant;

		const methods: any = {
			GET: this.get.bind(this),
			POST: this.post.bind(this),
			DELETE: this.delete.bind(this),

			PATCH: this.patch.bind(this),
			PUT: this.put.bind(this),
		};

		return methods[method](path, payload);
	}

	async requestArray<T extends ModelAbstract>(
		urlConstant: UrlConstant,
		// eslint-disable-next-line no-unused-vars
		model: new (data: any) => T,
		payload?: any,
	): Promise<T[] | Error> {
		try {
			await this.checkAndRenewToken();

			const apiData = await this.requestMethod(urlConstant, payload);

			if (!apiData || !Array.isArray(apiData)) {
				throw Error('token_not_valid');
			}

			return apiData.map((item: any) => {
				const constructedModel = new model(item);
				const validation = constructedModel.validate(item);
				if (validation) {
					throw validation;
				}
				return constructedModel;
			});
		} catch (error) {
			console.log('error', error);
			throw error;
		}
	}

	async request<T extends ModelAbstract>(
		urlConstant: UrlConstant,
		// eslint-disable-next-line no-unused-vars
		model: new (data: any) => T,
		payload?: any,
	): Promise<T | Error> {
		try {
			await this.checkAndRenewToken();

			const apiData = await this.requestMethod(urlConstant, payload);
			const constructedModel = new model(apiData);
			const validation = constructedModel.validate(apiData);

			if (validation) {
				throw validation;
			}

			return constructedModel;
		} catch (error) {
			console.log('error', error);
			throw error;
		}
	}

	private buildPathWithVars(path: string, data?: any): string {
		if (!data) {
			return path;
		}

		let pathWithVars = path;

		Object.keys(data).map(key => {
			if (!pathWithVars.includes(`:${key}`)) {
				return false;
			}
			pathWithVars = pathWithVars.replace(`:${key}`, data[key]);
			delete data[key];
			return true;
		});

		return pathWithVars;
	}

	async get(path: string, data?: any) {
		try {
			let pathWithVars = this.buildPathWithVars(path, data);
			if (data && data.query) {
				pathWithVars = `${pathWithVars}?${Object.keys(data.query)
					.map((key: any) => `${key}=${data.query[key]}`)
					.join('&')}`;
				delete data.query;
			}
			const response = await this.client.get(pathWithVars);

			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			console.log('Error', error);
			return error;
		}
	}

	async post(path: string, payload: any) {
		try {
			const pathWithVars = this.buildPathWithVars(path, payload);
			const response = await this.client.post(pathWithVars, payload);

			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			return error;
		}
	}

	async put(path: string, payload: any) {
		try {
			const pathWithVars = this.buildPathWithVars(path, payload);
			const response = await this.client.put(pathWithVars, payload);

			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			return error;
		}
	}

	async patch(path: string, payload: any) {
		try {
			const pathWithVars = this.buildPathWithVars(path, payload);
			const response = await this.client.patch(pathWithVars, { data: payload });

			if (response.statusText === 'OK') {
				return response.data;
			}
		} catch (error) {
			return error;
		}
	}

	async delete(path: string, payload: any) {
		try {
			const pathWithVars = this.buildPathWithVars(path, payload);
			const response = await this.client.delete(pathWithVars, payload);

			if (response.statusText === 'OK') {
				return response.data;
			}
		} catch (error) {
			return error;
		}
	}

	static i(): BonfireAPI {
		if (!BonfireAPI.instance) {
			BonfireAPI.instance = new BonfireAPI();
			BonfireAPI.instance.init();
		}
		return BonfireAPI.instance;
	}
}

export default BonfireAPI.i();
