import React from 'react';
import { Flex, Text, Spacer, Table, Tbody, Tr, Td, Box } from '@chakra-ui/react';
import Locale from '../../utils/translations';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import { LanguageOptionsModel, StatsModel } from '../../utils/interfaces';
// import { Link } from 'react-router-dom';

const StatisticsListItem = ({
	stats,
	getLanguageFromCode,
}: {
	stats: StatsModel;
	getLanguageFromCode: (code: string) => string;
}) => {
	const getPercentage = (value: number) => {
		return value.toFixed(2);
	};
	return (
		<Tr bg="white" borderRadius="1px" fontWeight="600" fontSize="14px">
			<Text variant="beTextDescription" ml={'10px'}>
				{getLanguageFromCode(stats.combination)}
			</Text>
			<Td border="1px solid #F5F1F1" borderRight="none" p="8px 16px" />

			<Td borderTop="1px solid #F5F1F1" display="grid" gridTemplateColumns={'33% 33% 33%'}>
				<Text variant="beTextDescription" ml={'20px'}>
					Positive votes: {getPercentage(stats.yesPercentage)}%
				</Text>
				<Text variant="beTextDescription">
					Negative votes: {getPercentage(stats.noPercentage)}%
				</Text>
				<Text variant="beTextDescription" ml={'20px'}>
					Not asnwered: {getPercentage(stats.notAnsweredPercentage)}%
				</Text>
			</Td>
		</Tr>
	);
};
const Statistics: React.FC = () => {
	const [languagesList, setLanguagesList] = React.useState<
		{ label: string; value: string }[] | []
	>([]);
	const [stats, setStats] = React.useState<StatsModel[]>([]);

	const loadStats = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const data = await api.get('qa/stats');

			setStats(data);
		} catch (error) {
			console.error(error);
		}
	};

	const loadLanguagesList = async () => {
		try {
			const api = new BonfireAPI(ApiType.BonfireCore);
			const data = await api.get('languages/get');

			const languagesOptions = data.map((language: LanguageOptionsModel) => {
				return { label: language.name, value: language.code };
			});

			setLanguagesList(languagesOptions);
		} catch (error) {
			console.error(error);
		}
	};

	const getLanguageFromCode = (codes: string): string => {
		if (languagesList.length === 0 || !codes.includes('-')) {
			return codes;
		}

		const [inputCode, outputCode] = codes.split('-');

		const findLanguage = (code: string): string => {
			const language = languagesList.find(
				(lang: { value: string; label: string }) => lang.value === code,
			);
			return language ? language.label : code;
		};

		const inputLangLabel = findLanguage(inputCode);
		const outputLangLabel = findLanguage(outputCode);

		return `${inputLangLabel} to ${outputLangLabel}`;
	};

	React.useEffect(() => {
		loadLanguagesList();
		loadStats();
		console.log(stats);
	}, []);

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Statistics')}
				</Text>
				<Spacer />
				{/* <Link to={'/translation'}>
					<Text fontSize="14px" fontWeight="400">
						{Locale.get('Click to go to translations to start voting')}
					</Text>
				</Link> */}
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{stats.length === 0 && (
						<Tr>
							<Td>{Locale.get('Loading...')}</Td>
						</Tr>
					)}
					{stats?.map((stat: StatsModel, index: number) => (
						<StatisticsListItem
							getLanguageFromCode={getLanguageFromCode}
							stats={stat}
							key={index}
						/>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default Statistics;
