import useSWR from 'swr';
import { useState } from 'react';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import { AxiosError } from 'axios';
import { GET_TRANSLATIONS } from '../../models/translations/urls';
import TranslationModel from '../../models/translations/translations';

const fetcher = async (url: string): Promise<TranslationModel[]> => {
	const api = new BonfireAPI(ApiType.QaApi);
	const params: any = {};
	url.split('?')[1]
		.split('&')
		.map(param => {
			const paramParts = param.split('=');
			params[paramParts[0]] = paramParts[1];
		});
	const translations: TranslationModel[] = (await api.requestArray(
		GET_TRANSLATIONS,
		TranslationModel,
		{ query: params },
	)) as TranslationModel[];

	if (translations instanceof AxiosError) {
		throw translations;
	}

	return translations;
};

export default function useTranslation(
	category: string,
	inputFilter?: string,
	outputFilter?: string,
) {
	const [offset, setOffset] = useState(0);
	const pageSize = 20;

	const { data, isLoading, error, mutate } = useSWR(
		`/qa/list?category=${category}&skip=${offset}&limit=${pageSize}&listAll=true${
			inputFilter && inputFilter.length !== 0 ? `&inputFilter=${inputFilter}` : ''
		}${outputFilter && outputFilter.length !== 0 ? `&outputFilter=${outputFilter}` : ''}`,
		fetcher,
	);

	const moveNext = () => {
		setOffset(offset + pageSize);
	};

	const movePrev = () => {
		let newOffset = offset - pageSize;
		if (newOffset < 0) {
			newOffset = 0;
		}
		setOffset(newOffset);
	};

	return {
		translations: data,
		translationsLoading: isLoading,
		translationsError: error,
		mutateTranslations: mutate,
		moveNext,
		movePrev,
		offset,
		pageSize,
	};
}
