import React from 'react';
import {
	Flex,
	Text,
	Spacer,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	IconButton,
} from '@chakra-ui/react';
import Locale from '../../utils/translations';
import useTranslation from '../hooks/useTranslations';
import { LikeButton, DislikeButton } from '../icons/icons';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import TranslationModel from '../../models/translations/translations';

const LanguageDetectionListItem = ({
	translation,
	onLikeButtonClicked,
	onDislikeButtonClicked,
}: {
	translation: TranslationModel;
	onLikeButtonClicked: (action: string, id: string) => void;
	onDislikeButtonClicked: (action: string, id: string) => void;
}) => {
	return (
		<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px">
			<Td
				border="1px solid #F5F1F1"
				borderRight="none"
				p="8px 16px"
				borderStartRadius="4px"
			/>

			<Td borderTop="1px solid #F5F1F1" display="grid" gridTemplateColumns="auto 1fr">
				<Text variant="beTextDescription">Audio:</Text>
				<Text variant="beTextDescription" ml={'20px'}>
					{translation.input.text}
					<audio controls>
						<source
							src={`https://api-qa.sandbox.ai-core.boostlingo.com/files/getFile?type=language-detection&id=${translation.input.text}`}
							type="audio/wav"
						/>
						Your browser does not support the audio element.
					</audio>
				</Text>
				<Text variant="beTextDescription" color="#1462F0">
					Recognized language:
				</Text>
				<Text variant="beTextDescription" color="#1462F0" ml={'20px'}>
					{translation.output.text}
				</Text>
				<Text variant="beTextDescription" color="#1462F0">
					Time used:
				</Text>
				<Text variant="beTextDescription" color="#1462F0" ml={'20px'}>
					{translation.getInputAttribute('time')}ms
				</Text>
			</Td>
			<Td borderTop="1px solid #F5F1F1" borderBottom="1px solid #F5F1F1">
				<IconButton
					onClick={() => onLikeButtonClicked('yes', translation._id)}
					colorScheme="#fff"
					size={'lg'}
					aria-label="yes-answer"
					icon={<LikeButton fill={translation.answer === 'yes' ? 'green' : '#1462F0'} />}
				/>
			</Td>
			<Td borderTop="1px solid #F5F1F1" borderBottom="1px solid #F5F1F1">
				<IconButton
					onClick={() => onDislikeButtonClicked('no', translation._id)}
					colorScheme="#fff"
					size={'lg'}
					aria-label="yes-answer"
					icon={<DislikeButton fill={translation.answer === 'no' ? 'red' : '#1462F0'} />}
				/>
			</Td>
		</Tr>
	);
};
const LanguageDetection: React.FC = () => {
	const {
		translations,
		translationsLoading,
		moveNext,
		movePrev,
		offset,
		pageSize,
		mutateTranslations,
	} = useTranslation('language_detection');

	const onLikeButtonClicked = async (action: string, id: string) => {
		const api = new BonfireAPI(ApiType.QaApi);
		await api.patch('/qa/updateAnswer', { action, id }).then(() => {
			mutateTranslations();
		});
	};

	if (translationsLoading) {
		return <div>loading ...</div>;
	}

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Language detection')}
				</Text>
				<Spacer />
				<Button
					isDisabled={offset === 0}
					variant="beSecondaryButton"
					size="sm"
					mr={5}
					onClick={movePrev}
				>
					previous
				</Button>
				<Text variant="beTextDescription">
					{offset + 1} .. {offset + pageSize}
				</Text>
				<Button
					isDisabled={translations?.length !== 20}
					variant="bePrimaryButton"
					size="sm"
					ml={5}
					onClick={moveNext}
				>
					next
				</Button>
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{translations?.map((translation: TranslationModel, index: number) => (
						<LanguageDetectionListItem
							translation={translation}
							key={index}
							onLikeButtonClicked={() => onLikeButtonClicked('yes', translation._id)}
							onDislikeButtonClicked={() =>
								onLikeButtonClicked('no', translation._id)
							}
						/>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default LanguageDetection;
