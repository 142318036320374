import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	inputAnatomy.keys,
);

const beVariant = definePartsStyle({
	field: {
		background: 'white',
		borderRadius: '4px',
		border: '1px solid #DDDDDD',
	},
});

export const inputTheme = defineMultiStyleConfig({
	variants: { beVariant },
});
