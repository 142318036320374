import intl from 'react-intl-universal';

export default class Locale {
	static get(key: string, vars?: any) {
		if (vars) {
			let defaultKey = key;

			if (vars.defaultKey) {
				defaultKey = vars.defaultKey;
				delete vars.defaultKey;
			}

			return intl.get(key, vars).d(defaultKey);
		}

		return intl.get(key).d(key);
	}
}
