import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './navigation';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './lib/theme/theme';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<BrowserRouter>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</BrowserRouter>,
);
