import React, { ReactElement } from 'react';

import Locale from '../../utils/translations';

import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface NavigationItemProps {
	icon: ReactElement;
	href: string;
	title: string;
	selected: boolean;
	onlyIcon: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
	icon,
	href,
	title,
	selected,
	onlyIcon,
}) => {
	return (
		<Link to={href} aria-label={Locale.get(`Navigate to ${title}`)}>
			<Flex
				_hover={{ bg: '#F0F3F6' }}
				mt="16px"
				alignItems="center"
				justifyContent={!onlyIcon ? 'center' : 'space-between'}
				padding="9px 8px"
				borderRadius="13px"
				bg={selected ? '#F0F3F6' : 'inherit'}
			>
				<Flex alignItems="center">
					{icon}
					<Text
						display={onlyIcon ? 'block' : 'none'}
						color={selected ? 'black' : 'textDescription'}
						fontWeight="600"
					>
						{Locale.get(title)}
					</Text>
				</Flex>
			</Flex>
		</Link>
	);
};

export default NavigationItem;
