import React from 'react';
import {
	Flex,
	Text,
	Spacer,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	Select,
	Input,
	RadioGroup,
	Stack,
	Radio,
	Tooltip,
} from '@chakra-ui/react';
import Locale from '../../utils/translations';
import useTranslation from '../hooks/useTranslations';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import { LanguageOptionsModel } from '../../utils/interfaces';
import TranslationModel from '../../models/translations/translations';

const TranslationListItem = ({
	translation,
	getLanguageFromCode,
}: {
	translation: TranslationModel;
	getLanguageFromCode: (code: string) => string;
}) => {
	const feedbackOptions = [
		{ value: 'Translation is 100% meaningful.' },
		{ value: 'Translation is meaningful, but could be done better by a human professional' },
		{
			value: 'Translation is overall ok, but certain key terms are misunderstood/poorly translated',
		},
		{ value: 'Translation is not correct' },
		{ value: 'Is source nonsense' },
	];

	const [selectedFeedback, setSelectedFeedback] = React.useState<string>(translation.answer);
	const [isOffensive, setIsOffensive] = React.useState<string>(
		translation.hasMaliciousOutput.toString(),
	);

	const handleFeedbackChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedFeedback(e.target.value);
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			api.patch('/qa/updateAnswer', { action: e.target.value, id: translation._id });
		} catch (error) {
			console.error(error);
		}
	};

	const handleIsOffensiveChange = (value: string) => {
		setIsOffensive(value);
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			api.patch('/qa/updateIsMalicious', {
				isMalicious: value,
				id: translation._id,
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px" display={'block'} mt={3}>
			<Tr>
				<Td borderRight="none" p="8px 16px" borderStartRadius="4px" />

				<Td gridTemplateColumns="auto 1fr">
					<Box mb={5}>
						<Text variant="beTextDescription">
							{getLanguageFromCode(translation.getInputAttribute('language'))}{' '}
							(source):
						</Text>
						<Text variant="beTextDescription" fontWeight={700} fontSize={18}>
							{translation.input.text}
						</Text>
					</Box>
					<Box>
						<Text variant="beTextDescription" color="#3182ce">
							{getLanguageFromCode(translation.getOutputAttribute('language'))}{' '}
							(translation):
						</Text>
						<Text
							variant="beTextDescription"
							color="#3182ce"
							fontWeight={700}
							fontSize={18}
						>
							{translation.output.text}
						</Text>
					</Box>
				</Td>
			</Tr>
			<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px">
				<Td borderRight="none" p="8px 16px" borderStartRadius="4px" />
				<Td>
					<Box>
						<Text variant="beTextDescription" alignContent="center" pb={2}>
							{Locale.get('Feedback on quality')}
						</Text>
						<Select
							alignContent="center"
							w="80%"
							placeholder="Select feedback"
							value={selectedFeedback}
							onChange={handleFeedbackChange}
							size={'sm'}
							borderRadius={'4px'}
						>
							{feedbackOptions.map((option, index) => (
								<option key={index} value={option.value}>
									{option.value}
								</option>
							))}
						</Select>
					</Box>
				</Td>
			</Tr>
			<Tr>
				<Td borderRight="none" p="8px 16px" borderStartRadius="4px" />
				<Td>
					<Box>
						<Text variant="beTextDescription" pr={2} alignContent="center" pb={2}>
							{Locale.get('Is this text potentially bad for AI translation?')}
						</Text>
						<RadioGroup
							w="80%"
							alignContent="center"
							value={isOffensive}
							onChange={handleIsOffensiveChange}
						>
							<Stack spacing={4} direction="row" mt={'1px'}>
								<Radio size="md" value="true">
									True
								</Radio>
								<Radio size="md" value="false">
									False
								</Radio>
							</Stack>
						</RadioGroup>
					</Box>
				</Td>
			</Tr>
		</Tr>
	);
};

const Translation: React.FC = () => {
	const [languagesList, setLanguagesList] = React.useState<
		{ label: string; value: string }[] | []
	>([]);
	const [inputFilter, setInputFilter] = React.useState<string | undefined>('');
	const [outputFilter, setOutputFilter] = React.useState<string | undefined>('');
	const [selectedTags, setSelectedTags] = React.useState<
		{
			sessionId: string;
			tags: string;
		}[]
	>([]);
	const [isTagsFieldEditable, setIsTagsFieldEditable] = React.useState<boolean>(false);

	const { translations, translationsLoading, moveNext, movePrev, offset, pageSize } =
		useTranslation('translation', inputFilter, outputFilter);

	const loadLanguagesList = async () => {
		try {
			const api = new BonfireAPI(ApiType.BonfireCore);
			const data = await api.get('languages/get');

			const languagesOptions = data.map((language: LanguageOptionsModel) => {
				return { label: language.name, value: language.code };
			});

			setLanguagesList(languagesOptions);
		} catch (error) {
			console.error(error);
		}
	};

	const onSaveTagClicked = (sessionId: string) => {
		const selectedSessionTags = selectedTags.filter(tag => tag.sessionId === sessionId);
		const api = new BonfireAPI(ApiType.QaApi);
		try {
			api.post('qa/addSessionTags', { sessionId, tags: selectedSessionTags[0].tags });
		} catch (error) {
			console.error(error);
		} finally {
			setIsTagsFieldEditable(false);
		}
	};

	const fetchSelectedTags = async () => {
		const api = new BonfireAPI(ApiType.QaApi);
		const data = await api.get('qa/getSessionTags');
		if (!data || data.length === 0) {
			return [];
		}
		setSelectedTags(data);
	};

	const getSpecificSessionTags = (sessionId: string) => {
		if (selectedTags.length === 0) {
			return '';
		}
		const sessionTags = selectedTags.filter(tag => tag.sessionId === sessionId);
		if (sessionTags.length === 0) {
			return '';
		}
		return sessionTags[0].tags ? sessionTags[0].tags : '';
	};

	const onChangeSpecificSessionTags = (
		e: React.ChangeEvent<HTMLInputElement>,
		sessionId: string,
	) => {
		const newTagValue = e.target.value;

		setSelectedTags(
			(
				prevTags: {
					sessionId: string;
					tags: string;
				}[],
			) => {
				// Check if there's an existing tag with the given sessionId
				const existingTagIndex = prevTags.findIndex(tag => tag.sessionId === sessionId);

				if (existingTagIndex !== -1) {
					// If found, update the existing tag
					return prevTags.map((tag, index) => {
						if (index === existingTagIndex) {
							return {
								...tag,
								tags: newTagValue,
							};
						}
						return tag;
					});
				}
				// If not found, push a new tag object into the array
				return [
					...prevTags,
					{
						sessionId,
						tags: newTagValue,
					},
				];
			},
		);
	};

	const onInputFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setOutputFilter('');
		setInputFilter(e.target.value);
	};

	const onOutputFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setInputFilter('');
		setOutputFilter(e.target.value);
	};

	const getLanguageFromCode = (code: string) => {
		if (languagesList.length === 0) {
			return code;
		}
		const lang = languagesList.find(
			(language: { value: string; label: string }) => language.value === code,
		);
		return lang?.label || code;
	};

	const sortByDate = (a: { date: string }, b: { date: string }): number => {
		return +new Date(a.date) - +new Date(b.date);
	};

	const groupBy = (array: any, key: any) => {
		return array.reduce((result: any, currentValue: any) => {
			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
			return result;
		}, {});
	};

	const groupedBySessionId = !translationsLoading ? groupBy(translations, 'sessionId') : [];

	React.useEffect(() => {
		loadLanguagesList();
		fetchSelectedTags();
	}, [translations]);

	if (translationsLoading) {
		return <div>loading ...</div>;
	}

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Translations')}
				</Text>
				<Spacer />
				<Select
					bgColor={'#fff'}
					borderRadius={'4px'}
					mr={2}
					size={'sm'}
					width={'20%'}
					placeholder="Filter by input language"
					value={inputFilter}
					onChange={onInputFilterChange}
				>
					{languagesList.map(
						(option: { value: string; label: string }, index: number) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						),
					)}
				</Select>
				<Select
					bgColor={'#fff'}
					borderRadius={'4px'}
					mr={2}
					size={'sm'}
					width={'20%'}
					placeholder="Filter by output language"
					value={outputFilter}
					onChange={onOutputFilterChange}
				>
					{languagesList.map(
						(option: { value: string; label: string }, index: number) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						),
					)}
				</Select>
				<Button
					isDisabled={offset === 0}
					variant="beSecondaryButton"
					size="sm"
					mr={5}
					onClick={movePrev}
				>
					previous
				</Button>
				<Text variant="beTextDescription">
					{offset + 1} .. {offset + pageSize}
				</Text>
				<Button
					isDisabled={translations?.length !== 20}
					variant="bePrimaryButton"
					size="sm"
					ml={5}
					onClick={moveNext}
				>
					next
				</Button>
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{Object.entries(groupedBySessionId).map(([sessionId, sessionData]) => {
						// Group by output language within each sessionId
						const groupedByLanguage = groupBy(sessionData, (item: any) => {
							const outputLanguage = item.output.attributes.find(
								(attr: any) => attr.key === 'language',
							);
							return outputLanguage ? outputLanguage.value : 'unknown';
						});

						return (
							<Box key={sessionId}>
								<Box>
									{sessionId.length > 0 ? (
										<Box mt={'30px'}>
											<Flex alignItems={'center'} py={1}>
												<Text variant="beTextDescription" fontWeight="700">
													{Locale.get(
														'Session conversation: (add tags separated by commas to describe content of the session. e.g medical,sports)',
													)}
												</Text>
											</Flex>
											<Flex
												alignItems={'center'}
												flexDirection={'row'}
												justifyContent={'flex-start'}
											>
												<Text fontSize={14} fontWeight={400} pb={2} pr={2}>
													{Locale.get('Tags')}
												</Text>
												<Tooltip
													label={
														!isTagsFieldEditable
															? 'Press Edit tags button to start editing'
															: ''
													}
												>
													<Input
														pr={2}
														width={'50%'}
														shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
														fontWeight="400"
														variant={'beVariant'}
														_placeholder={{ fontWeight: 'normal' }}
														readOnly={!isTagsFieldEditable}
														value={getSpecificSessionTags(sessionId)}
														onChange={e =>
															onChangeSpecificSessionTags(
																e,
																sessionId,
															)
														}
													/>
												</Tooltip>{' '}
												<Button
													pr={2}
													pb={2}
													variant="bePrimaryButton"
													size="sm"
													isDisabled={!isTagsFieldEditable}
													onClick={() => onSaveTagClicked(sessionId)}
												>
													{Locale.get('Save tags')}
												</Button>
												<Button
													pr={2}
													pb={2}
													variant="bePrimaryButton"
													size="sm"
													onClick={() =>
														setIsTagsFieldEditable(!isTagsFieldEditable)
													}
												>
													{Locale.get('Edit tags')}
												</Button>
											</Flex>
										</Box>
									) : (
										<Text variant="beTextDescription" fontWeight="700">
											{Locale.get(
												'Messagees below are not attached to a specific session',
											)}
										</Text>
									)}
								</Box>
								{Object.entries(groupedByLanguage).map(([language, messages]) => {
									// Sort messages by date
									const sortedMessages = (messages as any).sort(sortByDate);

									return (
										<Box key={language}>
											{sortedMessages.map((message: any, index: any) => (
												<TranslationListItem
													getLanguageFromCode={getLanguageFromCode}
													translation={message}
													key={index}
												/>
											))}
										</Box>
									);
								})}
							</Box>
						);
					})}
				</Tbody>
			</Table>
		</Box>
	);
};

export default Translation;
