/* eslint-disable @typescript-eslint/no-shadow */
import development from '../config/development.json';
import sandbox from '../config/sandbox.json';
import staging from '../config/staging.json';
import productionEU from '../config/prod-eu.json';
import productionUS from '../config/prod-us.json';

enum Environment {
	development = 'development',
	production = 'productionEU',
	productionUS = 'productionUS',
	sandbox = 'sandbox',
	staging = 'staging',
}

let env: Environment;
// eslint-disable-next-line no-undef
switch (process.env.REACT_APP_ENV) {
	case 'development':
		env = Environment.development;
		break;
	case 'production':
		env = Environment.production;
		break;
	case 'prod-us':
		env = Environment.productionUS;
		break;
	case 'sandbox':
		env = Environment.sandbox;
		break;
	case 'staging':
		env = Environment.staging;
		break;
	default:
		env = Environment.sandbox;
}

const config = {
	development,
	productionEU,
	productionUS,
	sandbox,
	staging,
};

export default config[env];
