import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const beSubTextHeading = defineStyle({
	fontWeight: '600',
	fontSize: '18px',
	color: 'primaryBlue',
});

const beTextHeading = defineStyle({
	fontWeight: '700',
	fontSize: '32px',
	color: 'primaryBlue',
});

const beTextDescription = defineStyle({
	fontWeight: '600',
	fontSize: '14px',
	color: 'textDescription',
});

export const textTheme = defineStyleConfig({
	variants: { beSubTextHeading, beTextDescription, beTextHeading },
});
