import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
	header: {
		bg: 'white',
		padding: '16px 24px',
		borderTopRadius: '4px',
	},
	body: {
		padding: '32px 24px',
	},
	dialog: {
		bg: '#F0F3F6',
	},
});

export const modalTheme = defineMultiStyleConfig({ baseStyle });
