import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const beVariant = defineStyle({
	background: 'white',
	border: '1px solid #DDDDDD',
	borderRadius: '4px',
});

const beAddButton = defineStyle({
	background: 'white',
	border: '2px solid #DDDDDD',
	borderRadius: '40px',
	_hover: {
		background: '#DDDDDD',
	},
});

const buttonStyle = {
	borderRadius: '4px',
	padding: '15px 24px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'white',
};

const bePrimary = defineStyle({
	...buttonStyle,
	bg: 'primaryBlue',
	_hover: { bg: 'primaryBlue', _disabled: { bg: 'primaryBlue' } },
	_disabled: { _hover: { bg: 'primaryBlue' } },
});

const beSecondary = defineStyle({
	...buttonStyle,
	bg: 'secondaryBlue',
	_hover: { bg: 'secondaryBlue', _disabled: { bg: 'secondaryBlue' } },
	_disabled: { _hover: { bg: 'secondaryBlue' } },
});

const beDanger = defineStyle({
	...buttonStyle,
	bg: 'danger',
	_hover: { bg: 'danger', _disabled: { bg: 'danger' } },
	_disabled: { _hover: { bg: 'danger' } },
});

const beSuccess = defineStyle({
	...buttonStyle,
	bg: 'success',
	_hover: { bg: 'success' },
});

const beWarning = defineStyle({
	...buttonStyle,
	bg: 'warning',
	_hover: { bg: 'warning' },
});

const beOutline = defineStyle({
	border: '2px solid',
	borderColor: 'primaryBlue',
	bg: 'white',
	color: 'primaryBlue',
	borderRadius: '4px',
	padding: '13px 24px',
	fontSize: '14px',
});

export const buttonTheme = defineStyleConfig({
	variants: {
		beOutline,
		beVariant,
		beAddButton,
		bePrimary,
		beSecondary,
		beDanger,
		beSuccess,
		beWarning,
	},
});
