import { HttpMethod } from '../methods';
import { UrlConstant } from '../../utils/interfaces';

export const GET_USER: UrlConstant = { path: '/users/me', method: HttpMethod.GET };
export const PATCH_USER: UrlConstant = { path: '/users/me', method: HttpMethod.PATCH };
export const RESEND_ACTIVATION_CODE: UrlConstant = {
	path: '/users/free-trial/resend',
	method: HttpMethod.POST,
};
export const ACTIVATE_USER: UrlConstant = {
	path: '/users/free-trial/activate',
	method: HttpMethod.POST,
};
