import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Flex, Input, Text } from '@chakra-ui/react';
//import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import config from '../../../utils/config';
import SelectedAudio from './SelectedAudio';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';

const SpeechToSpeechFlowRenderer: React.FC = () => {
	const { sessionId } = useParams<{ sessionId: string }>();
	const [sessionProcessedData, setSessionProcessedData] = React.useState<any>([]);
	const [width, setWidth] = useState(1000); // Initial width of the chart
	const [height, setHeight] = useState(500); // Initial height of the chart
	const [itemSelected, setItemSelected] = useState<any>(null);
	const [renderIsFinal, setRenderIsFinal] = useState<boolean>(false);

	const firstStart = useRef(0);
	const processedDataRef = useRef<any>([]);
	const sessionData = useRef<any>([]);

	const getFromAttributes = (attributes: any, key: string) => {
		const value = attributes.find((attribute: any) => {
			return attribute.key === key;
		})?.value;
		return value || '';
	};

	const buildLength = (item: any) => {
		if (item.type === 'transcription') {
			return new Date(item.start);
		}
		if (item.type === 'utterance') {
			return new Date(item.start);
		}
		if (item.type === 'languagechanged') {
			return new Date(item.start);
		}

		return new Date(item.start + item.length * 1000);
	};

	const getSeconds = (time: number) => {
		return `[${(time - firstStart.current) / 1000}s]`;
	};

	const buildToolTip = (item: any) => {
		if (item.type === 'translation') {
			return `${getSeconds(item.start)} ${item.text} (${getFromAttributes(
				item.attributes,
				'resultText',
			)}) / ${item.length}s`;
		}

		if (item.type === 'transcription') {
			const isFinal = getFromAttributes(item.attributes, 'isFinal');
			const realStart = parseInt(getFromAttributes(item.attributes, 'realStart'));
			const confidence = getFromAttributes(item.attributes, 'confidence');
			let start = realStart + item.length * 1000;
			let duration = (item.start - (realStart + item.length * 1000)) / 1000;
			if (item.start === realStart) {
				start = item.start;
				duration = item.length;
			}
			if (isFinal === '1') {
				return `${getSeconds(start)} ${item.text}  / ${duration}s / ${confidence}`;
			}
			return `${getSeconds(start)} ${item.text} (not final) / ${duration}s / ${confidence}`;
		}

		if (item.type === 'utterance') {
			const lastWordEnd = getFromAttributes(item.attributes, 'lastWordEnd');
			return `${getSeconds(lastWordEnd)} Utterance / ${(item.start - lastWordEnd) / 1000}s`;
		}

		if (item.type === 'languagechanged') {
			return `${getSeconds(item.start)} Language changed`;
		}

		if (item.type === 'tts') {
			return `${getSeconds(item.start)} ${item.text}  / ${item.length}s`;
		}

		if (item.type === 'participant_activity') {
			return `${getSeconds(item.start)} ${item.length}s / ${item.text}`;
		}
	};

	const buildStyle = (item: any) => {
		if (item.type === 'transcription') {
			const isFinal = getFromAttributes(item.attributes, 'isFinal');
			if (isFinal !== '1') {
				return 'opacity: 0.5; stroke-color: black';
			}
			return 'opacity: 1; stroke-color: black';
		}

		if (item.type === 'tts') {
			if (item.action === 'queue') {
				return 'opacity: 0.5; stroke-color: black';
			}
		}

		return 'opacity: 1; stroke-color: black';
	};

	const buildStart = (item: any) => {
		if (item.type === 'utterance') {
			const lastWordEnd = getFromAttributes(item.attributes, 'lastWordEnd');
			return new Date(parseInt(lastWordEnd));
		}

		if (item.type === 'transcription') {
			const realStart = parseInt(getFromAttributes(item.attributes, 'realStart'));
			return new Date(realStart + item.length * 1000);
		}

		return new Date(item.start);
	};

	const buildRowName = (item: any) => {
		if (item.type === 'participant_activity') {
			return `Activity from ${item.text}`;
		}

		return `${item.type} [${item.language.toUpperCase()}]`;
	};

	const processData = (data: any) => {
		const processedData: any = [];
		setWidth(data.length * 100);
		processedDataRef.current = [];
		firstStart.current = 0;

		data.map((item: any) => {
			if (!item.start) {
				return;
			}
			if (item.type === 'transcription') {
				const realStart = parseInt(getFromAttributes(item.attributes, 'realStart'));
				const isFinal = parseInt(getFromAttributes(item.attributes, 'isFinal'));

				if (isFinal === 0 && !renderIsFinal) {
					return;
				}

				if (firstStart.current === 0) {
					firstStart.current = realStart;
				}
				processedData.push([
					`Voice [${item.language.toUpperCase()}]`,
					null,
					buildStyle(item),
					buildToolTip({ ...item, start: realStart }),
					new Date(realStart),
					new Date(realStart + item.length * 1000),
				]);
				processedDataRef.current.push({ ...item, subtype: 'voice' });
			}

			const start = buildStart(item);
			const length = buildLength(item);

			processedData.push([
				buildRowName(item),
				null,
				buildStyle(item),
				buildToolTip(item),
				start,
				length < start ? start : length,
			]);
			processedDataRef.current.push(item);
		});

		setSessionProcessedData(processedData);
	};

	const loadData = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const sessionRemoteData = await api.post('s2sflow/getBySessionId', { sessionId });
			sessionData.current = sessionRemoteData;
			processData(sessionRemoteData);
		} catch (error) {
			console.error(error);
		}
	};

	const renderSelectedItemAudio = async (item: any) => {
		setItemSelected(item);
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		processData(sessionData.current);
	}, [renderIsFinal]);

	return (
		<Flex flexDirection={'column'}>
			<Flex width={600} flexDirection="row">
				<Input
					type="text"
					width={200}
					value={width}
					onChange={e => setWidth(parseInt(e.target.value))}
				/>
				<Input
					type="text"
					width={200}
					value={height}
					onChange={e => setHeight(parseInt(e.target.value))}
				/>
				<Flex ml={5} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
					<Checkbox
						onChange={e => {
							setRenderIsFinal(e.target.checked);
						}}
						checked={renderIsFinal}
					/>
					<Text ml={2}>Render isFinal</Text>
				</Flex>
			</Flex>
			<audio controls>
				<source
					src={`${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`}
					type="audio/wav"
				/>
				Your browser does not support the audio element.
			</audio>
			<ResizableBox
				width={width}
				height={height}
				minConstraints={[500, 500]} // Minimum width and height
				maxConstraints={[80000, 80000]} // Maximum width and height
				onResize={(event: any, { size }: any) => {
					setWidth(size.width);
					setHeight(size.height);
				}}
				resizeHandles={['se']} // Resizing handle direction
				style={{ border: '1px solid #ccc', padding: '10px' }}
			>
				{sessionProcessedData && (
					<Box width={'100%'} height={'100%'}>
						<Chart
							chartType="Timeline"
							data={[
								[
									{ type: 'string', id: 'Type' },
									{ type: 'string', id: 'dummy bar label' },
									{ type: 'string', role: 'style' },
									{ type: 'string', role: 'tooltip' },
									{ type: 'date', id: 'Start' },
									{ type: 'date', id: 'End' },
								],
								...sessionProcessedData,
							]}
							width={'100%'}
							height={'100%'}
							chartEvents={[
								{
									eventName: 'select',
									callback: ({ chartWrapper }) => {
										const selection = chartWrapper.getChart().getSelection();
										if (selection.length === 0) {
											return;
										}

										const item = processedDataRef.current[selection[0].row];
										renderSelectedItemAudio(item);
									},
								},
							]}
						/>
					</Box>
				)}
			</ResizableBox>
			<SelectedAudio
				item={itemSelected}
				sessionId={sessionId}
				onlyAudio={false}
				bufferData={null}
			/>
		</Flex>
	);
};

export default SpeechToSpeechFlowRenderer;
