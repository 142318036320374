import React, { useEffect } from 'react';
import { Button, Flex, Input, Text } from '@chakra-ui/react';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { useParams } from 'react-router-dom';
import 'react-resizable/css/styles.css';
import config from '../../../utils/config';
import SelectedAudio from './SelectedAudio';

const RenderSessionChunks: React.FC = () => {
	const { sessionId } = useParams<{ sessionId: string }>();
	const [sessionData, setSessionData] = React.useState<any>([]);
	const [sessionFeedback, setSessionFeedback] = React.useState<any>(null);
	const [chunkFeedback, setChunkFeedback] = React.useState<any>({});
	const [fetchingDone, setFetchingDone] = React.useState<boolean>(false);
	const [haveRecording, setHaveRecording] = React.useState<boolean>(false);
	const [processStatus, setProcessStatus] = React.useState<string>('');

	const saveTimeout = React.useRef<any>();
	const saveChunkTimeout = React.useRef<any>();
	const buffer = React.useRef<any>();

	const getFromAttributes = (attributes: any, key: string) => {
		const value = attributes.find((attribute: any) => {
			return attribute.key === key;
		})?.value;
		return value || '';
	};

	const loadData = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const sessionRemoteData = await api.post('s2sflow/getBySessionId', { sessionId });
			const chunkFeedbacks: any = {};
			setSessionData(sessionRemoteData);
			setProcessStatus(sessionRemoteData[0].processStatus);
			for (const key in sessionRemoteData) {
				const item = sessionRemoteData[key];
				chunkFeedbacks[item._id] = item.chunkFeedback;
				if (item.sessionFeedback) {
					setSessionFeedback(item.sessionFeedback);
				}
			}
			setChunkFeedback(chunkFeedbacks);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchAudioFile = async () => {
		if (!sessionId) {
			return;
		}
		if (buffer.current) {
			return;
		}

		let response;

		try {
			const url = `${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`;
			response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status !== 200) {
				setFetchingDone(true);
				return;
			}
			setHaveRecording(true);
		} catch (error) {
			setFetchingDone(true);
			return;
		}

		const audioContext = new window.AudioContext();
		const arrayBuffer = await response?.arrayBuffer();
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
		buffer.current = audioBuffer;
		setFetchingDone(true);
	};

	const changeSessionProcessedStatus = async (status: string) => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			setProcessStatus(status);
			await api.post('s2sflow/changeSessionProcessedStatus', {
				sessionId,
				status,
			});
		} catch (error) {
			if (status === 'processed') {
				setProcessStatus('added');
			} else {
				setProcessStatus('processed');
			}
			console.error(error);
		}
	};

	const chunkChanged = (chunkId: string, data: any, wait: boolean = false) => {
		if (saveChunkTimeout.current) {
			clearTimeout(saveChunkTimeout.current);
		}

		saveChunkTimeout.current = setTimeout(
			() => {
				const api = new BonfireAPI(ApiType.QaApi);
				api.post('s2sflow/saveChunkFeedback', {
					sessionId,
					chunkId,
					feedback: data,
				});
			},
			wait ? 800 : 0,
		);
	};

	useEffect(() => {
		loadData();
		fetchAudioFile();
	}, []);

	useEffect(() => {
		if (!sessionFeedback) {
			return;
		}

		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current);
		}

		saveTimeout.current = setTimeout(() => {
			const api = new BonfireAPI(ApiType.QaApi);
			api.post('s2sflow/saveSessionFeedback', {
				sessionId,
				feedback: { ...sessionFeedback },
			});
		}, 800);
	}, [sessionFeedback]);

	return (
		<Flex flexDirection={'column'}>
			<Flex
				flexDirection={'column'}
				borderWidth={1}
				borderColor={'#ccc'}
				p={5}
				backgroundColor={'#fff'}
			>
				<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
					<Text mr={3} fontWeight={'bold'}>
						Session:{' '}
					</Text>
					<Text mr={3}>{sessionId}</Text>
				</Flex>

				<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
					<Text mr={3} fontWeight={'bold'}>
						Topic:
					</Text>
					<Input
						value={sessionFeedback?.topic || ''}
						onChange={e => {
							setSessionFeedback({ ...sessionFeedback, topic: e.target.value });
						}}
					/>
				</Flex>

				<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
					<Text mr={3} fontWeight={'bold'}>
						Audio quality:
					</Text>
					<Flex>
						<Button
							variant={
								sessionFeedback?.audioQuality === 'good' ? 'beSuccess' : 'beOutline'
							}
							mr={3}
							onClick={() => {
								setSessionFeedback({ ...sessionFeedback, audioQuality: 'good' });
							}}
						>
							GOOD
						</Button>
						<Button
							variant={
								sessionFeedback?.audioQuality === 'bad' ? 'beDanger' : 'beOutline'
							}
							mr={3}
							onClick={() => {
								setSessionFeedback({ ...sessionFeedback, audioQuality: 'bad' });
							}}
						>
							BAD
						</Button>
					</Flex>
				</Flex>

				{haveRecording && (
					<audio controls>
						<source
							src={`${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`}
							type="audio/wav"
						/>
						Your browser does not support the audio element.
					</audio>
				)}
			</Flex>

			{sessionData.length > 0 &&
				sessionData.map((item: any) => {
					const isFinal = getFromAttributes(item.attributes, 'isFinal');
					if (item.type !== 'transcription' || isFinal === '0') {
						return null;
					}
					const chunkInfo = chunkFeedback[item._id];
					return (
						<Flex
							key={item._id}
							backgroundColor={'#fff'}
							borderWidth={1}
							borderColor={'#ccc'}
							mt={3}
							mb={3}
							p={5}
							flexDirection={'column'}
						>
							{haveRecording && fetchingDone && (
								<SelectedAudio
									item={item}
									sessionId={sessionId}
									onlyAudio={true}
									bufferData={buffer.current}
								/>
							)}
							<Flex
								mb={3}
								backgroundColor={'#3182ce'}
								p={1}
								color={'#fff'}
								width={'40px'}
								fontWeight={'bold'}
								alignItems={'center'}
								justifyContent={'center'}
							>
								{item.language}
							</Flex>
							<Text p={2} mt={2} mb={5} backgroundColor={'#EEE'} fontSize={25}>
								{item.text}
							</Text>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Language detection good:
								</Text>
								<Flex>
									<Button
										variant={
											chunkInfo?.languageDetectionGood === 'yes'
												? 'beSuccess'
												: 'beOutline'
										}
										mr={3}
										onClick={() => {
											const data = {
												...chunkInfo,
												languageDetectionGood: 'yes',
											};
											setChunkFeedback({
												...chunkFeedback,
												[item._id]: data,
											});
											chunkChanged(item._id, data);
										}}
									>
										YES
									</Button>
									<Button
										variant={
											chunkInfo?.languageDetectionGood === 'no'
												? 'beDanger'
												: 'beOutline'
										}
										mr={3}
										onClick={() => {
											const data = {
												...chunkInfo,
												languageDetectionGood: 'no',
											};
											setChunkFeedback({
												...chunkFeedback,
												[item._id]: data,
											});
											chunkChanged(item._id, data);
										}}
									>
										NO
									</Button>
								</Flex>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									ASR correct:
								</Text>
								<Flex>
									<Button
										variant={
											chunkInfo?.asrCorrect === 'yes'
												? 'beSuccess'
												: 'beOutline'
										}
										mr={3}
										onClick={() => {
											const data = {
												...chunkInfo,
												asrCorrect: 'yes',
											};
											setChunkFeedback({
												...chunkFeedback,
												[item._id]: data,
											});
											chunkChanged(item._id, data);
										}}
									>
										YES
									</Button>
									<Button
										variant={
											chunkInfo?.asrCorrect === 'no'
												? 'beDanger'
												: 'beOutline'
										}
										mr={3}
										onClick={() => {
											const data = {
												...chunkInfo,
												asrCorrect: 'no',
												correctText: !chunkInfo?.correctText
													? item.text
													: chunkInfo.correctText,
											};
											setChunkFeedback({
												...chunkFeedback,
												[item._id]: data,
											});
											chunkChanged(item._id, data);
										}}
									>
										NO
									</Button>
									<Button
										variant={
											chunkInfo?.asrCorrect === 'usable'
												? 'beWarning'
												: 'beOutline'
										}
										mr={3}
										onClick={() => {
											const data = {
												...chunkInfo,
												asrCorrect: 'usable',
												correctText: !chunkInfo?.correctText
													? item.text
													: chunkInfo.correctText,
											};
											setChunkFeedback({
												...chunkFeedback,
												[item._id]: data,
											});
											chunkChanged(item._id, data);
										}}
									>
										USABLE
									</Button>
								</Flex>
							</Flex>
							{chunkInfo?.asrCorrect && chunkInfo?.asrCorrect != 'yes' && (
								<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
									<Text mr={3} fontWeight={'bold'}>
										Correct text:
									</Text>
									<Flex>
										<Input
											width={'800px'}
											value={chunkInfo?.correctText}
											onChange={e => {
												const data = {
													...chunkInfo,
													correctText: e.target.value,
												};
												setChunkFeedback({
													...chunkFeedback,
													[item._id]: data,
												});
												chunkChanged(item._id, data, true);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
					);
				})}
			{processStatus && (
				<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
					<Text mr={3} fontWeight={'bold'}>
						Session processed:
					</Text>
					<Flex>
						<Button
							variant={processStatus === 'processed' ? 'beSuccess' : 'beOutline'}
							mr={3}
							onClick={() => {
								changeSessionProcessedStatus('processed');
							}}
						>
							YES
						</Button>
						<Button
							variant={processStatus === 'added' ? 'beWarning' : 'beOutline'}
							mr={3}
							onClick={() => {
								changeSessionProcessedStatus('added');
							}}
						>
							NO
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default RenderSessionChunks;
