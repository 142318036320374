import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function BoostEventsLogo(props: IconProps) {
	return (
		<Icon fill="#2C3F63" stroke="#2E6DA4" viewBox="1001.5 80.1 1597.7 919.9" {...props}>
			<g>
				<path d="M1546.4,464.5c10.8,0,19.5-9,19.5-20c0-11.1-8.8-20-19.5-20h-189.5c-10.8,0-19.5,9-19.5,20   c0,11.1,8.8,20,19.5,20H1546.4z" />
				<path d="M1646.3,540.2c0-11.1-8.8-20-19.5-20h-347.5c-10.8,0-19.5,9-19.5,20c0,11.1,8.8,20,19.5,20h347.5   C1637.6,560.2,1646.3,551.2,1646.3,540.2z" />
				<path d="M1356.8,612.2c-10.8,0-19.5,9-19.5,20c0,11.1,8.8,20,19.5,20h189.5c10.8,0,19.5-9,19.5-20   c0-11.1-8.8-20-19.5-20H1356.8z" />
				<path d="M2243.5,464.5c10.8,0,19.5-9,19.5-20c0-11.1-8.8-20-19.5-20h-189.5c-10.8,0-19.5,9-19.5,20   c0,11.1,8.8,20,19.5,20H2243.5z" />
				<path d="M1954,540.2c0,11.1,8.8,20,19.5,20h347.5c10.8,0,19.5-9,19.5-20c0-11.1-8.8-20-19.5-20h-347.5   C1962.8,520.2,1954,529.1,1954,540.2z" />
				<path d="M2053.9,612.2c-10.8,0-19.5,9-19.5,20c0,11.1,8.8,20,19.5,20h189.5c10.8,0,19.5-9,19.5-20   c0-11.1-8.8-20-19.5-20H2053.9z" />
				<path d="M2599,540.2c0-254-201-459.9-449-459.9c-110.9,0-212.3,41.2-290.6,109.5c-21.6,18.8-41.4,39.6-59.2,62.2   c-62,78.9-99.2,179.1-99.2,288.2h-0.2c0,63-22.3,120.7-59.1,165.3c-45.9,55.7-114.5,91.1-191.3,91.1   c-138.3,0-250.4-114.8-250.4-256.5c0-141.6,112.1-256.5,250.4-256.5c76.7,0,145.3,35.4,191.3,91.1l99.4-185.1   c-78.3-68.2-179.8-109.5-290.6-109.5c-248,0-449,205.9-449,459.9c0,95.3,28.3,183.8,76.7,257.2l-76.7,202.7l198.5-78.2   c71.6,49.4,157.8,78.2,250.6,78.2c110.9,0,212.3-41.2,290.6-109.5c21.6-18.8,41.4-39.6,59.2-62.2c61.9-78.7,99.1-178.7,99.2-287.7   l0.2-0.6c0-63,22.3-120.7,59.1-165.3c45.9-55.7,114.5-91.1,191.3-91.1c138.3,0,250.4,114.8,250.4,256.5   c0,141.6-112.1,256.5-250.4,256.5c-76.7,0-145.3-35.4-191.3-91.1c-21.2,68.6-55.3,131.2-99.4,185.1   c78.3,68.2,179.8,109.5,290.6,109.5c92.8,0,179-28.8,250.6-78.2l198.5,78.2l-76.7-202.7C2570.7,724,2599,635.5,2599,540.2z" />
			</g>
		</Icon>
	);
}

export function FireIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 16 19" {...props}>
			<path d="M11.6055 2.07812C14.1719 4.43359 16 8.30078 16 10.1641C16 14.6289 12.4492 18.25 8.125 18.25C3.76562 18.25 0.25 14.6289 0.25 10.1641C0.25 7.63281 2.67578 3.48438 6.15625 0.25C7.49219 1.51562 8.6875 2.85156 9.63672 4.1875C10.2344 3.44922 10.8672 2.74609 11.6055 2.07812ZM8.125 16.5625C11.5352 16.5625 14.3125 13.7148 14.3125 10.1641C14.3125 9.07422 13.1875 6.50781 11.5703 4.50391C11.3594 4.75 11.1484 4.99609 10.9375 5.24219L9.56641 6.92969L8.26562 5.17188C7.66797 4.32812 6.92969 3.44922 6.15625 2.64062C3.48438 5.48828 1.9375 8.58203 1.9375 10.1641C1.9375 13.7148 4.71484 16.5625 8.125 16.5625ZM11.2539 8.82812C11.3945 9.03906 11.5 9.21484 11.6055 9.42578C12.4492 11.043 12.0977 13.082 10.5859 14.1367C9.91797 14.6289 9.07422 14.875 8.19531 14.875C5.98047 14.875 4.1875 13.4336 4.1875 11.043C4.1875 9.84766 4.92578 8.79297 6.40234 7C6.61328 7.24609 9.46094 10.8672 9.46094 10.8672L11.2539 8.82812Z" />
		</Icon>
	);
}

export function GroupPeopleIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 23 19" {...props}>
			<path d="M11.7148 11.5C9.71094 11.5 8.05859 9.88281 8.09375 7.84375C8.09375 5.83984 9.71094 4.1875 11.7148 4.1875C13.7188 4.1875 15.3711 5.83984 15.3711 7.84375C15.3711 9.88281 13.7539 11.5 11.7148 11.5ZM11.7148 5.875C10.6602 5.875 9.74609 6.78906 9.74609 7.84375C9.74609 8.93359 10.6602 9.8125 11.7148 9.8125C12.8047 9.8125 13.6836 8.93359 13.6836 7.84375C13.6836 6.78906 12.8047 5.875 11.7148 5.875ZM18.5 5.875C16.918 5.875 15.6875 4.64453 15.6875 3.0625C15.6875 1.51562 16.918 0.25 18.5 0.25C20.0469 0.25 21.3125 1.51562 21.3125 3.0625C21.3125 4.64453 20.0469 5.875 18.5 5.875ZM13.4727 12.625C16.25 12.625 18.5 14.7344 18.5 17.3359C18.5 17.8633 18.043 18.25 17.4805 18.25H5.98438C5.42188 18.25 5 17.8633 5 17.3359C5 14.7344 7.21484 12.625 9.99219 12.625H13.4727ZM6.75781 16.5625H16.707C16.3203 15.2969 15.0195 14.3125 13.4727 14.3125H9.95703C8.41016 14.3125 7.10938 15.2969 6.75781 16.5625ZM19.8711 7C21.5938 7 23 8.47656 23 10.3047C23 10.6562 22.7188 10.9375 22.3672 10.9375H15.3359C16.0742 10.1289 16.4961 9.03906 16.4961 7.84375C16.4961 7.66797 16.4609 7.49219 16.4609 7.31641C16.8477 7.14062 17.2695 7 17.7266 7H19.8711ZM6.93359 7.84375C6.93359 9.03906 7.39062 10.1289 8.12891 10.9375H1.09766C0.746094 10.9375 0.5 10.6562 0.5 10.3047C0.5 8.47656 1.87109 7 3.59375 7H5.73828C6.19531 7 6.61719 7.10547 7.00391 7.31641C6.96875 7.49219 6.93359 7.66797 6.93359 7.84375ZM5 5.875C3.41797 5.875 2.1875 4.64453 2.1875 3.0625C2.1875 1.51562 3.41797 0.25 5 0.25C6.54688 0.25 7.8125 1.51562 7.8125 3.0625C7.8125 4.64453 6.54688 5.875 5 5.875Z" />
		</Icon>
	);
}

export function CloseNavBar(props: IconProps) {
	return (
		<Icon viewBox="0 0 19 16" {...props}>
			<path d="M0.749999 12.625L0.75 3.625C0.75 1.72656 2.22656 0.249999 4.125 0.249999L6.65625 0.249999C7.11328 0.249999 7.5 0.601562 7.5 1.09375C7.5 1.55078 7.11328 1.9375 6.65625 1.9375L4.125 1.9375C3.17578 1.9375 2.4375 2.67578 2.4375 3.625L2.4375 12.625C2.4375 13.5391 3.17578 14.3125 4.125 14.3125L6.65625 14.3125C7.11328 14.3125 7.5 14.6641 7.5 15.1562C7.5 15.6133 7.11328 16 6.65625 16L4.125 16C2.26172 16 0.749999 14.4883 0.749999 12.625ZM6.58594 8.6875L11.0859 13.4687C11.4023 13.8203 11.9297 13.8203 12.2812 13.5039C12.6328 13.1875 12.6328 12.6602 12.3164 12.3086L9.15234 8.96875L17.9062 8.96875C18.3633 8.96875 18.75 8.58203 18.75 8.125C18.75 7.63281 18.3633 7.28125 17.9062 7.28125L9.15234 7.28125L12.3164 3.90625C12.6328 3.55469 12.6328 3.02734 12.2812 2.71094C12.1055 2.57031 11.8945 2.5 11.7187 2.5C11.4727 2.5 11.2617 2.57031 11.0859 2.74609L6.58594 7.52734C6.26953 7.84375 6.26953 8.37109 6.58594 8.6875Z" />
		</Icon>
	);
}

export function OpenNavBar(props: IconProps) {
	return (
		<Icon viewBox="0 0 19 16" {...props}>
			<path d="M7.5 15.1562C7.5 15.6133 7.11328 16 6.65625 16L4.125 16C2.22656 16 0.749999 14.4883 0.749999 12.625L0.75 3.625C0.75 1.72656 2.22656 0.249999 4.125 0.249999L6.65625 0.249999C7.11328 0.249999 7.5 0.601562 7.5 1.09375C7.5 1.55078 7.11328 1.9375 6.65625 1.9375L4.125 1.9375C3.17578 1.9375 2.4375 2.67578 2.4375 3.625L2.4375 12.625C2.4375 13.5391 3.17578 14.3125 4.125 14.3125L6.65625 14.3125C7.11328 14.3125 7.5 14.6641 7.5 15.1562ZM18.5039 7.52734L14.0391 2.74609C13.6875 2.39453 13.1602 2.39453 12.8086 2.71094C12.457 3.02734 12.457 3.55469 12.7734 3.90625L15.9375 7.28125L7.21875 7.28125C6.72656 7.28125 6.375 7.63281 6.375 8.125C6.375 8.58203 6.72656 8.96875 7.18359 8.96875L15.9375 8.96875L12.7734 12.3086C12.457 12.6602 12.457 13.1875 12.8086 13.5039C12.9844 13.6445 13.1953 13.75 13.4062 13.75C13.6172 13.75 13.8281 13.6445 14.0039 13.4687L18.4687 8.6875C18.8203 8.37109 18.8203 7.84375 18.5039 7.52734Z" />
		</Icon>
	);
}

export function LikeButton(props: IconProps) {
	return (
		<Icon viewBox="0 0 512 512" {...props}>
			<path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
		</Icon>
	);
}

export function DislikeButton(props: IconProps) {
	return (
		<Icon viewBox="0 0 512 512" {...props}>
			<path d="M323.8 477.2c-38.2 10.9-78.1-11.2-89-49.4l-5.7-20c-3.7-13-10.4-25-19.5-35l-51.3-56.4c-8.9-9.8-8.2-25 1.6-33.9s25-8.2 33.9 1.6l51.3 56.4c14.1 15.5 24.4 34 30.1 54.1l5.7 20c3.6 12.7 16.9 20.1 29.7 16.5s20.1-16.9 16.5-29.7l-5.7-20c-5.7-19.9-14.7-38.7-26.6-55.5c-5.2-7.3-5.8-16.9-1.7-24.9s12.3-13 21.3-13L448 288c8.8 0 16-7.2 16-16c0-6.8-4.3-12.7-10.4-15c-7.4-2.8-13-9-14.9-16.7s.1-15.8 5.3-21.7c2.5-2.8 4-6.5 4-10.6c0-7.8-5.6-14.3-13-15.7c-8.2-1.6-15.1-7.3-18-15.2s-1.6-16.7 3.6-23.3c2.1-2.7 3.4-6.1 3.4-9.9c0-6.7-4.2-12.6-10.2-14.9c-11.5-4.5-17.7-16.9-14.4-28.8c.4-1.3 .6-2.8 .6-4.3c0-8.8-7.2-16-16-16H286.5c-12.6 0-25 3.7-35.5 10.7l-61.7 41.1c-11 7.4-25.9 4.4-33.3-6.7s-4.4-25.9 6.7-33.3l61.7-41.1c18.4-12.3 40-18.8 62.1-18.8H384c34.7 0 62.9 27.6 64 62c14.6 11.7 24 29.7 24 50c0 4.5-.5 8.8-1.3 13c15.4 11.7 25.3 30.2 25.3 51c0 6.5-1 12.8-2.8 18.7C504.8 238.3 512 254.3 512 272c0 35.3-28.6 64-64 64l-92.3 0c4.7 10.4 8.7 21.2 11.8 32.2l5.7 20c10.9 38.2-11.2 78.1-49.4 89zM32 384c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H32z" />{' '}
		</Icon>
	);
}
