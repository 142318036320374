import { menuTheme } from './components/beMenuTheme';
import { badgeTheme } from './components/beBadgeTheme';
import { extendTheme } from '@chakra-ui/react';
import { alertTheme } from './components/beAlertTheme';
import { beAvatarTheme } from './components/beAvatarTheme';
import { inputTheme } from './components/beInputTheme';
import { buttonTheme } from './components/beButtonTheme';
import { MultiSelectTheme } from 'chakra-multiselect';
import { textTheme } from './components/beTextTheme';
import { modalTheme } from './components/beModalTheme';

const theme = extendTheme({
	colors: {
		primaryBlue: '#1E599E',
		secondaryBlue: '#19607C',
		danger: '#C55B5B',
		success: '#5cb85c',
		warning: '#faa632',
		textDescription: '#4b5563',
		mutedBlue: '#C7E8FE',
		mutedGreen: '#BBEAD9',
	},
	components: {
		Menu: menuTheme,
		Badge: badgeTheme,
		Alert: alertTheme,
		Avatar: beAvatarTheme,
		Input: inputTheme,
		Button: buttonTheme,
		Text: textTheme,
		MultiSelect: MultiSelectTheme,
		Modal: modalTheme,
	},
	styles: {
		global: {
			'html, body': {
				'overflow-x': 'hidden',
			},
		},
	},
});

export default theme;
