// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ModelAbstract from '../modelAbstract/modelAbstract';

export default class TranslationModel extends ModelAbstract {
	_id: string;
	category: string;
	date: Date | string;
	input: {
		attributes: { key: string; value: string }[];
		text: string;
	};
	output: {
		attributes: { key: string; value: string }[];
		text: string;
	};
	answer: string;
	sessionId: string;
	hasMaliciousOutput: boolean;

	constructor(data: any) {
		super();

		this._id = data._id;
		this.category = data.category;
		this.date = data.date;
		this.input = data.input;
		this.output = data.output;
		this.answer = data.answer;
		this.sessionId = data.sessionId;
		this.hasMaliciousOutput = data.hasMaliciousOutput;

		this.mapData();
	}

	mapData(): void {}

	getInputAttribute(attributeName: string): string {
		const attribute = this.input.attributes.find(attr => attr.key === attributeName);
		return attribute ? attribute.value : '';
	}

	getOutputAttribute(attributeName: string): string {
		const attribute = this.output.attributes.find(attr => attr.key === attributeName);
		return attribute ? attribute.value : '';
	}

	validate(data: any): string {
		if (data._id) {
			return null;
		}

		if (data.error) {
			return data.error;
		}

		return 'Unexpected error';
	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
